<script>
import ValidationMessage from '@/components/general/ValidationMessage'
export default {
  name: 'ValidationTemplate',
  components: { ValidationMessage },
  props: {
    validation: {
      type: Object
    },
    hideValidation: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div class="validation--wrapper" :class="{'hide-validation': hideValidation}">
    <slot/>
    <div v-if="!hideValidation" class="validation--message">
      <validation-message
        v-if="validation"
        :validation="validation"
      />
    </div>
  </div>
</template>
<style lang="scss">
.validation--wrapper {
  @media only screen and (max-width: 768px) {
    &.hide-validation {
      margin-bottom: 16px;
    }
  }
  .validation--message {
    text-align: left;
    min-height: 30px;
  }
}
</style>

<script>
import SelectField from '@/components/general/SelectField'
import ModalInviteMember from '@/components/workspace/businessWorkspace/WorkspaceManagement/ModalInviteMember'

export default {
  name: 'MemberSubmenuTabInvite',
  components: {
    ModalInviteMember,
    SelectField
  },
  data () {
    return {
      showModalInvite: false,
      sortBy: 'recent',
      sortOptions: [{ text: this.$t('global:sort.recent'), value: 'recent' }, { text: this.$t('global:sort.alphabetic'), value: 'alphabetic' }],
      header: [
        { text: this.$t('workspace.member.modal.header:name'), value: 'name' },
        { text: this.$t('workspace.member.modal.header:status'), value: 'status' },
        { text: this.$t('workspace.member.modal.header:lastAction'), value: 'lastAction' },
        { text: this.$t('workspace.member.modal.header:actions'), value: 'action', width: '50px' }
      ],
      page: 1,
      itemsPerPage: 5
    }
  },
  computed: {
    inviteList () {
      return this.selectedWorkspace.invites || []
    },
    pagedInviteList () {
      const zeroPageOffset = 1
      return this.inviteList.slice((this.page - zeroPageOffset) * this.itemsPerPage, this.page * this.itemsPerPage)
    }
  },
  methods: {
    updatePage (page) {
      this.page = page
    },
    showPaginationText ({ pageStart, pageStop, itemsLength }) {
      return `${pageStart}-${pageStop} ${this.$t('skill.modal.pagination.preposition')} ${itemsLength}`
    },
    closeInviteModal () {
      this.showModalInvite = false
      this.loadInvites()
    },
    loadInvites () {
      const payload = {
        workspaceId: this.selectedWorkspace.lxID,
        filter: {
          pagination: {
            page: this.page
          },
          sortBy: this.sortBy
        }
      }
      this.$store.dispatch('attemptGetWorkspaceInvites', payload)
    },
    resendInvite (invite) {
      if (invite.resendIsAllowed) {
        this.$store.dispatch('attemptResendInviteWorkspaceMember', invite.id)
          .then(() => {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t('modal.invite.member.resend:feedback.success')
            })
          })
          .finally(() => {
            this.loadInvites()
          })
      } else {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('modal.invite.member.resend:feedback.error')
        })
      }
    }
  },
  created () {
    this.loadInvites()
  }
}
</script>
<template>
  <div class="member-submenu-invite--container">
    <div class="workspace-management--members__actions">
      <v-btn
        outlined
        class="btn"
        color="#1200D3"
        @click="showModalInvite = true"
      ><v-icon>mdi-plus</v-icon>{{ $t('workspace.management.submenu.member:invite') }}</v-btn>
      <select-field class="order-by--select" outlined small v-model="sortBy" :items="sortOptions" />
    </div>
    <div class="workspace-management--members__list">
      <v-data-table
        class="members-table--wrapper"
        :headers="header"
        :items="inviteList"
        :items-per-page="itemsPerPage"
        :hide-default-footer="inviteList.length === 0"
        :page="page"
        @update:page="updatePage"
        disable-sort
        hide-default-header
        :footer-props="{ 'items-per-page-options': [5] }"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="h in headers" :key="h.value" :width="h.width" class="table-header--wrapper">
              <span class="table-header--item">{{h.text}} </span>
            </th>
          </tr>
          </thead>
        </template>
        <template
          v-if="inviteList.length > 0"
          v-slot:body
        >
          <tbody>
          <tr
            v-for="(member, index) in pagedInviteList"
            :key="index"
            class="member-table--line"
          >
            <td class="text-left overflow-text">
              <div class="workspace-members--user-info">
                <v-img class="workspace-members--user-picture" :src="member.avatarUrl || defaultAvatarUrl('profile')"></v-img>
                <span class="workspace-members--user-name">{{ member.name ? `${member.name} ${member.lastName}` : member.email }} {{  }}</span>
              </div>
            </td>
            <td class="text-left">{{ $t(`global.status:${member.status}`) }}</td>
            <td class="text-left">{{ formatDate(member.lastActionSent) }}</td>
            <td class="text-center">
              <v-tooltip top max-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="workspace-members--resend-action" v-on="on" v-bind="attrs">
                    <v-icon @click="resendInvite(member)" :disabled="!member.resendIsAllowed">mdi-email-send-outline</v-icon>
                  </div>
                </template>
                <span>{{ $t(member.resendIsAllowed ? 'global:resend' : 'member.invite.list:resend.tip') }}</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
        <template
          v-if="inviteList.length === 0"
          v-slot:no-data
        >
          {{ $t('members.modal.table:no.invites.found') }}
        </template>
        <template v-slot:[`footer.page-text`]="data">
          {{ showPaginationText(data) }}
        </template>
      </v-data-table>
    </div>
    <modal-invite-member :show="showModalInvite" @close="closeInviteModal"/>
  </div>
</template>
<style lang="scss">
.member-submenu-invite--container {
  .workspace-management--members__actions {
    .order-by--select {
      margin-top: 20px;
    }
  }
  .workspace-members--resend-action {
    .v-icon {
      color: $primary-medium;
    }
  }
  .members-table--wrapper {
    margin-bottom: 120px;
  }
}
</style>

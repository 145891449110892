<script>
export default {
  name: 'validationMessage',
  props: {
    validation: {
      type: Object,
      default: null
    },
    currency: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="form-input-messages-container" v-if="validation && validation.$error">
    <v-icon class="form-input-icon">mdi-information</v-icon>
    <span class="form-input-message" v-if="!validation.minLength && validation.minLength !== undefined">{{ $t('global:validation.minlength', {'num': validation.$params.minLength.min}) }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordContainNumber && validation.passwordContainNumber !== undefined">{{ $t('global:validation.invalid.number') }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordContainSpecialCharacter && validation.passwordContainSpecialCharacter !== undefined">{{ $t('global:validation.invalid.special.character') }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordContainUpperCharacter && validation.passwordContainUpperCharacter !== undefined">{{ $t('global:validation.invalid.upper.character') }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordContainLowerCharacter && validation.passwordContainLowerCharacter !== undefined">{{ $t('global:validation.invalid.lower.character') }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordIsValid && validation.passwordIsValid !== undefined">{{ $t('global:validation.invalid.password') }}</span>
    <span class="form-input-message" v-else-if="!validation.passwordConfirmation && validation.passwordConfirmation !== undefined">{{ $t('global:validation.different.password') }}</span>
    <span class="form-input-message" v-else-if="validation.passwordOrEmailInvalid && validation.passwordOrEmailInvalid.value !== undefined">{{ $t('global:validation.different.passwordOrEmail') }}</span>
    <span class="form-input-message" v-else-if="!validation.required && validation.required !== undefined">{{ $t('global:validation.required') }}</span>
    <span class="form-input-message" v-else-if="!validation.maxLength && validation.maxLength !== undefined">{{ $t('global:validation.maxlength', {'num': validation.$params.maxLength.max}) }}</span>
    <span class="form-input-message" v-else-if="!validation.alpha && validation.alpha !== undefined">{{ $t('global:validation.alpha') }}</span>
    <span class="form-input-message" v-else-if="!validation.alphaNum && validation.alphaNum !== undefined">{{ $t('global:validation.alphanum') }}</span>
    <span class="form-input-message" v-else-if="!validation.userEmail && validation.userEmail !== undefined">{{ $t('global:validation.email') }}</span>
    <span class="form-input-message" v-else-if="!validation.usernameValidator && validation.usernameValidator !== undefined">{{ $t('global:validation.username') }}</span>
    <span class="form-input-message" v-else-if="!validation.usernameExistsValidator && validation.usernameExistsValidator !== undefined">{{ $t('global:validation.username.exists') }}</span>
    <span class="form-input-message" v-else-if="!validation.email && validation.email !== undefined">{{ $t('global:validation.email') }}</span>
    <span class="form-input-message" v-else-if="!validation.minValue && validation.minValue !== undefined">{{ $t('global:validation.minvalue', {'num': validation.$params.minValue.min}) }}</span>
    <span class="form-input-message" v-else-if="!validation.valueBiggerZero && validation.valueBiggerZero !== undefined">{{ $t('global:validation.valuebiggerzero') }}</span>
    <span class="form-input-message" v-else-if="!validation.maxValue && validation.maxValue !== undefined">{{ $t('global:validation.maxvalue', {'num': currency ? $n(validation.$params.maxValue.max, 'currency') : validation.$params.maxValue.max}) }}</span>
    <span class="form-input-message" v-else-if="!validation.between && validation.between !== undefined">{{ $t('global:validation.between', {'num1': currency ? $n(validation.$params.between.min, 'currency').replace(/^(\D+)/, '$1 ') : validation.$params.between.min, 'num2': currency ? $n(validation.$params.between.max, 'currency').replace(/^(\D+)/, '$1 ') : validation.$params.between.max}) }}</span>
    <span class="form-input-message" v-else-if="!validation.url && validation.url !== undefined">{{ $t('global:validation.url') }}</span>
    <span class="form-input-message" v-else-if="!validation.validFormatMonthYear && validation.validFormatMonthYear !== undefined">{{ $t('global:validation.invalid.date')}}</span>
    <span class="form-input-message" v-else-if="!validation.limitDateMonthYear && validation.limitDateMonthYear !== undefined">{{ $t('global:validation.future.limit.date') }}</span>
    <span class="form-input-message" v-else-if="!validation.limitDateYear && validation.limitDateYear !== undefined">{{ $t('global:validation.future.limit.date') }}</span>
    <span class="form-input-message" v-else-if="!validation.validateBirthDate && validation.validateBirthDate !== undefined">{{ $t('global:validation.invalid.date')}}</span>
    <span class="form-input-message" v-else-if="!validation.initEndDate && validation.initEndDate !== undefined">{{ $t('global:validation.future.date')}}</span>
    <span class="form-input-message" v-else-if="!validation.validatePhoneNumber && validation.validatePhoneNumber !== undefined"> {{ $t('global:validation.phone')}} </span>
    <span class="form-input-message" v-else-if="!validation.notUsedEmail && validation.notUsedEmail !== undefined">{{ $t('global:validation.email.exists')}}</span>
    <span class="form-input-message" v-else-if="!validation.isValidEmail && validation.isValidEmail !== undefined">{{ $t('global:validation.email') }}</span>
    <span class="form-input-message" v-else-if="!validation.validatePhoneDDD && validation.validatePhoneDDD !== undefined">{{ $t('global:validation.areaCode')}}</span>
    <span class="form-input-message" v-else-if="!validation.validateSocialMedias && validation.validateSocialMedias !== undefined">{{ $t('global:validation.invalid.socialmedia') }}</span>
    <span class="form-input-message" v-else-if="!validation.fileSize && validation.fileSize !== undefined">{{ $t('global:validation.file.size', {'num':(validation.$params.fileSize.size / 1024 / 1024)}) }}</span>
    <span class="form-input-message" v-else-if="!validation.isEnliztEmail && validation.isEnliztEmail !== undefined">{{ $t('global:validation.invalid.enlizt.email') }}</span>
    <span class="form-input-message" v-else-if="validation.required && !validation.mimeType && validation.mimeType !== undefined">{{ $t('global:validation.mimetype') }}</span>
  </div>
</template>
<style lang="scss">
.form-input-messages-container {
  margin-bottom: 8px;
  .form-input-icon {
    color: $warning-medium;
    margin-right: 4px;
    &.v-icon {
      font-size: 18px;
    }
  }
  .form-input-message {
    font-size: 12px;
    color: $warning-medium;
  }
}
</style>
